import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaYoutube } from "react-icons/fa";
import { GiClick } from "react-icons/gi";

function GetInTouch() {
    const [isVisible, setIsVisible] = useState(false);
    const componentRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        observer.observe(componentRef.current);

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setIsVisible(false);
    }, [componentRef]);

    return (
        <div ref={componentRef} className={`text-white font-bold home-text px-3 ${isVisible ? 'slide-in-left' : ''}`}>
            <div className="flex justify-center mt-8 text-sm md:text-base lg:text-lg lg:mt-[4rem]">
                <div className="flex justify-center space-x-2">
                    <button className="bg-[#2980B9] hover:bg-[#1F618D] text-white py-2 px-4 md:py-1 md:px-2 lg:py-2 lg:px-4 rounded glow-on-hover1" onClick={() => navigate('/products/xai-bot')}>
                        <div className='flex justify-center items-center space-x-1'>
                            <span>Watch Demo</span>
                            <FaYoutube size={20} />
                        </div>
                    </button>
                    <button className="bg-[#2980B9] hover:bg-[#1F618D] text-white py-2 px-4 md:py-1 md:px-2 lg:py-2 lg:px-4 rounded glow-on-hover1" onClick={() => navigate('/contact')}>
                        <div className='flex justify-center items-center space-x-1'>
                            <span>Get in Touch</span>
                            <GiClick size={20} />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GetInTouch;