import React from 'react';
import { FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FaThreads } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { MdEmail, MdPhone } from 'react-icons/md';

function SocialMediaIcons() {
    const isSmallScreen = window.innerWidth <= 768;

    if (isSmallScreen) {
        return (
            <div className="flex flex-col items-center justify-center space-y-2">
                <div className='flex justify-center items-center space-x-3'>
                    <SocialMediaIcon icon={<FaLinkedinIn />} link="https://www.linkedin.com/company/tensor-solution-tech-private-limited" />
                    <SocialMediaIcon icon={<FaThreads />} link="https://www.threads.net/@tensorsolution" />
                </div>
                <div className='flex justify-center items-center space-x-3'>
                    <SocialMediaIcon icon={<FaInstagram />} link="https://www.instagram.com/tensorsolution" />
                    <SocialMediaIcon icon={<FaYoutube />} link="https://www.youtube.com/channel/UC29O8qlXwBrCaObjCmHJ8gg" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex items-center space-x-4">
                <SocialMediaIcon icon={<FaLinkedinIn />} link="https://www.linkedin.com/company/tensor-solution-tech-private-limited" />
                <SocialMediaIcon icon={<FaThreads />} link="https://www.threads.net/@tensorsolution" />
                <SocialMediaIcon icon={<FaInstagram />} link="https://www.instagram.com/tensorsolution" />
                <SocialMediaIcon icon={<FaYoutube />} link="https://www.youtube.com/channel/UC29O8qlXwBrCaObjCmHJ8gg" />
            </div>
        );
    }
}

function SocialMediaIcon({ icon, link }) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="block p-2 bg-white text-homeBg hover:bg-blue-500 hover:text-white rounded-full transition duration-300">
            {icon}
        </a>
    );
}

function Footer() {
    const isSmallScreen = window.innerWidth <= 768;

    if (isSmallScreen) {
        return (
            <footer className="bg-homeBg text-white py-8 mt-[5rem]">
                <div className="container mx-auto flex flex-col md:flex-row justify-between px-4">
                    <div className="mb-4 md:mb-0 md:w-1/3 md:mr-10">
                        <h2 className="text-xl font-bold mb-2">Tensor</h2>
                        <p className="text-sm">We deeply understand our clients' challenges and offer progressive technological solutions through incremental implementation for swift benefits.</p>
                        <div>
                            <a href="mailto:tensor.solutiontech@gmail.com" className="text-[1rem] mt-4 block flex items-center space-x-1">
                                <MdEmail /> <span>tensor.solutiontech@gmail.com</span>
                            </a>
                            <a href="tel:+919560187169" className="text-[1rem] mt-2 block flex items-center space-x-1">
                                <MdPhone /> <span>+91-9560187169</span>
                            </a>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:flex-grow mt-6 px-1">
                        <div className='flex justify-between'>
                            <div>
                                <h3 className="text-lg font-semibold mb-2">What we do</h3>
                                <ul className="text-sm">
                                    <li><Link to="/">Home</Link></li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Who we are</h3>
                                <ul className="text-sm">
                                    <li className='mb-2'><Link to="/about">About Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Products</h3>
                                <ul className="text-sm">
                                    <li><Link to="/products/bhashantar">Tensor AI</Link></li>
                                    <li><Link to="/products/xai-bot">Tensor LLM</Link></li>
                                    <li><Link to="/products/smurfur">Tensor Play</Link></li>
                                </ul>
                            </div>
                            <div className='mr-6'>
                                <h3 className="text-lg font-semibold mb-2">Follow us</h3>
                                <SocialMediaIcons />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-center items-center mt-[3rem] md:mt-6 space-y-2 md:space-y-0 md:space-x-2'>
                    <p className='text-[0.8rem] md:text-sm'>© 2024 Tensor Solution Tech Pvt Ltd.</p>
                    <p className='text-[0.8rem] md:text-sm'>All Rights Reserved. <Link to="/privacy-policy" className="text-white">Privacy policy</Link></p>
                </div>
            </footer>
        )
    } else {
        return (
            <footer className="bg-homeBg text-white py-8 mt-[5rem]">
                <div className="container mx-auto flex flex-col md:flex-row justify-between px-4">
                    <div className="mb-4 md:mb-0 md:w-1/3 md:mr-10">
                        <h2 className="text-xl font-bold mb-2">Tensor</h2>
                        <p className="text-sm">We deeply understand our clients' challenges and offer progressive technological solutions through incremental implementation for swift benefits.</p>
                        <div>
                            <a href="mailto:tensor.solutiontech@gmail.com" className="text-[1rem] mt-4 block flex items-center space-x-1">
                                <MdEmail /> <span>tensor.solutiontech@gmail.com</span>
                            </a>
                            <a href="tel:+919560187169" className="text-[1rem] mt-2 block flex items-center space-x-1">
                                <MdPhone /> <span>+91-9560187169</span>
                            </a>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:flex-grow">
                        <div>
                            <h3 className="text-lg font-semibold mb-2">What we do</h3>
                            <ul className="text-sm">
                                <li><Link to="/">Home</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Who we are</h3>
                            <ul className="text-sm">
                                <li className='mb-2'><Link to="/about">About Us</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Products</h3>
                            <ul className="text-sm">
                                <li><Link to="/products/bhashantar">Tensor AI</Link></li>
                                <li><Link to="/products/xai-bot">Tensor LLM</Link></li>
                                <li><Link to="/products/smurfur">Tensor Play</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Follow us</h3>
                            <SocialMediaIcons />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-center items-center mt-3 md:mt-6 space-y-2 md:space-y-0 md:space-x-2'>
                    <p className='text-[0.8rem] md:text-sm'>© 2024 Tensor Solution Tech Pvt Ltd.</p>
                    <p className='text-[0.8rem] md:text-sm'>All Rights Reserved. <Link to="/privacy-policy" className="text-white">Privacy policy</Link></p>
                </div>
            </footer>
        )
    }
}

export default Footer;