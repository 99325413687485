import React, { useState } from 'react';
import AboutBg from '../../assests/location.png';
import OfficeImg from '../../assests/office.png';
import { CiLocationOn } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { IoCallOutline } from "react-icons/io5";

function Contactus() {
    const isSmallScreen = window.innerWidth <= 768;
    const [apiError, setApiError] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
        mobile: false,
        message: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setFormErrors(prevState => ({
            ...prevState,
            [name]: false
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await fetch('https://formspree.io/f/myyrbpal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                if (response.ok) {
                    setSuccessMsg('Thank you for contacting us! We have received your message and will get back to you shortly.');
                    setFormData({
                        name: '',
                        email: '',
                        mobile: '',
                        message: '',
                    });
                    setTimeout(() => {
                        setSuccessMsg(null);
                    }, 5000);
                } else {
                    setApiError('Form submission failed');
                }
            } catch (error) {
                setApiError('Something went wrong.');
            } finally {
                setLoading(false);
            }
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!formData.name.trim()) {
            errors.name = true;
            isValid = false;
        }

        if (!formData.email.trim()) {
            errors.email = true;
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            errors.email = true;
            isValid = false;
        }

        if (!formData.mobile.trim() || formData.mobile.trim().length !== 10 || isNaN(formData.mobile.trim())) {
            errors.mobile = true;
            isValid = false;
        }

        if (!formData.message.trim()) {
            errors.message = true;
            isValid = false;
        }

        setFormErrors(errors);

        return isValid;
    };

    return (
        <div>
            <div className='flex justify-around items-center p-2 bg-homeBg bg-home-png'>
                <div className='text-white'>
                    <p className='text-white font-bold about-text'>Contact Us</p>
                    <p className='my-3'>Home / Pages / Contact</p>
                </div>
                <div className='relative p-3'>
                    <img src={AboutBg} alt="icon" width="130" height="130" />
                    <div className="absolute inset-0 bg-homeBg opacity-20 rounded-lg"></div>
                </div>
            </div>
            {isSmallScreen ? (
                <div className="container mx-auto py-8">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 px-5">
                        <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                            <p className='p-2 mb-4 flex justify-center'><CiLocationOn size={40} color='#2980B9' /></p>
                            <h2 className="text-lg font-bold mb-2">Address</h2>
                            <p>63-B, Indra Vihar Colony,</p>
                            <p>Airport Road, Bhopal, M.P. 462030</p>
                        </div>
                        <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                            <p className='p-2 mb-4 flex justify-center'><TfiEmail size={38} color='#2980B9' /></p>
                            <h2 className="text-lg font-bold mb-2">Write Us</h2>
                            <p><a href="mailto:tensor.solutiontech@gmail.com">tensor.solutiontech@gmail.com</a></p>
                        </div>
                        <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                            <p className='p-2 mb-4 flex justify-center'><IoCallOutline size={40} color='#2980B9' /></p>
                            <h2 className="text-lg font-bold mb-2">Call Us</h2>
                            <p><a href="tel:+919560187169">+91-9560187169</a></p>
                        </div>
                    </div>
                </div>

            )
                :
                (
                    <div className="container mx-auto py-8">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 px-5">
                            <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                                <p className='p-2 mb-4 flex justify-center'><CiLocationOn size={40} color='#2980B9' /></p>
                                <h2 className="text-lg font-bold mb-2">Address</h2>
                                <p>63-B, Indra Vihar Colony,</p>
                                <p>Airport Road, Bhopal, M.P. 462030</p>
                            </div>
                            <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                                <p className='p-2 mb-4 flex justify-center'><TfiEmail size={38} color='#2980B9' /></p>
                                <h2 className="text-lg font-bold mb-2">Write Us</h2>
                                <p><a href="mailto:tensor.solutiontech@gmail.com">tensor.solutiontech@gmail.com</a></p>
                            </div>
                            <div className="bg-bgLight p-4 rounded-md shadow-md text-center">
                                <p className='p-2 mb-4 flex justify-center'><IoCallOutline size={40} color='#2980B9' /></p>
                                <h2 className="text-lg font-bold mb-2">Call Us</h2>
                                <p><a href="tel:+919560187169">+91-9560187169</a></p>
                            </div>
                        </div>
                    </div>
                )}
            {isSmallScreen ? (
                <div className='container flex flex-col items-center mx-auto p-4 my-[3rem]'>
                    <div className="shadow p-8 rounded-md w-full bg-slate-100">
                        <h2 className='text-3xl font-bold mb-8 text-center'>Write Us</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 rounded-md p-2 focus:outline-none ${formErrors.name ? 'border-red-500' : ''}`}
                                    placeholder="Full Name"
                                />
                                {formErrors.name && <p className="text-red-500 text-sm mt-1">Enter your full name</p>}
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 focus:outline-none rounded-md p-2 ${formErrors.mobile ? 'border-red-500' : ''}`}
                                    placeholder="Mobile Number"
                                />
                                {formErrors.mobile && <p className="text-red-500 text-sm mt-1">Please enter a valid mobile number</p>}
                            </div>
                            <div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 focus:outline-none rounded-md p-2 ${formErrors.email ? 'border-red-500' : ''}`}
                                    placeholder="Email Address"
                                />
                                {formErrors.email && <p className="text-red-500 text-sm mt-1">Please enter valid email address</p>}
                            </div>
                            <div>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 rounded-md p-2 focus:outline-none ${formErrors.message ? 'border-red-500' : ''}`}
                                    rows="4"
                                    placeholder="Message"
                                ></textarea>
                                {formErrors.message && <p className="text-red-500 text-sm mt-1">Please enter your message</p>}
                            </div>
                            {apiError && <p className="text-red-500 text-sm mt-1">{apiError}</p>}
                            {!apiError && successMsg && <p className="text-[#1D8348] font-semibold text-sm mt-1">{successMsg}</p>}
                            <button type="submit" className="w-full bg-homeBg text-white font-bold py-2 rounded-md hover:bg-blue-600 transition duration-300" disabled={loading}>
                                {loading ? <span>Sending...</span> : <span>Submit</span>}
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <div className='container flex justify-around mx-auto py-8'>
                    <div>
                        <img src={OfficeImg} alt="icon" width="330" height="330" />
                    </div>
                    <div className="ml-8 shadow-xl p-8 rounded-md" style={{ width: '500px' }}>
                        <h2 className='text-3xl font-bold my-5'>Write Us</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 bg-bgLight rounded-md p-2 focus:outline-none ${formErrors.name ? 'border-red-500' : ''}`}
                                    placeholder="Full Name"
                                />
                                {formErrors.name && <p className="text-red-500 text-sm mt-1">Enter your full name</p>}
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    className={`w-full border-red-300 bg-bgLight focus:outline-none rounded-md p-2 ${formErrors.mobile ? 'border-red-500' : ''}`}
                                    placeholder="Mobile Number"
                                />
                                {formErrors.mobile && <p className="text-red-500 text-sm mt-1">Please enter a valid mobile number</p>}
                            </div>
                            <div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 bg-bgLight focus:outline-none rounded-md p-2 ${formErrors.email ? 'border-red-500' : ''}`}
                                    placeholder="Email Address"
                                />
                                {formErrors.email && <p className="text-red-500 text-sm mt-1">Please enter valid email address</p>}
                            </div>
                            <div>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className={`w-full border-gray-300 bg-bgLight rounded-md p-2 focus:outline-none ${formErrors.message ? 'border-red-500' : ''}`}
                                    rows="4"
                                    placeholder="Message"
                                ></textarea>
                                {formErrors.message && <p className="text-red-500 text-sm mt-1">Please type your message here</p>}
                            </div>
                            {apiError && <p className="text-red-500 text-sm mt-1">{apiError}</p>}
                            {!apiError && successMsg && <p className="text-[#1D8348] font-semibold text-sm mt-1">{successMsg}</p>}
                            <button type="submit" className="w-full bg-homeBg text-white font-bold py-2 rounded-md hover:bg-blue-600 transition duration-300" disabled={loading}>
                                {loading ? <span>Sending...</span> : <span>Submit</span>}
                            </button>
                        </form>
                    </div>
                </div>
            )}
            <div className='shadow-lg px-4'>
                <div className="w-full h-96">
                    <iframe
                        className="w-full h-full"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.95586305615!2d77.35667427442422!3d23.281053406634616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c67c6f6ede10b%3A0x4fc19107b0b82c24!2s63-B%2C%20Indra%20Vihar%20Colony%2C%20Tilak%20Nagar%2C%20Airport%20Road%2C%20Bhopal%2C%20Madhya%20Pradesh%20462030!5e0!3m2!1sen!2sin!4v1709026591320!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Map"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Contactus;