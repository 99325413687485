import React, { useState, useEffect, useRef } from 'react';
import HomeIcon from '../../assests/home-icon2.png';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import { FaYoutube } from "react-icons/fa";
import { GiClick } from "react-icons/gi";

function Home() {
    const [isVisible, setIsVisible] = useState(false);
    const componentRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        observer.observe(componentRef.current);

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setIsVisible(false);
    }, [componentRef]);

    return (
        <div className='flex flex-col md:flex-row justify-center items-center py-8 bg-homeBg bg-home-png md:space-x-8 shadow-lg md:pb-[9rem]'>
            <div className={`${isVisible ? 'slide-in-right' : ''} md:hidden w-full px-5 mb-5 flex justify-center`}>
                <img src={HomeIcon} alt="icon" className="w-40 h-40 object-cover" />
            </div>
            <div ref={componentRef} className={`text-white md:w-[70%] font-bold home-text px-3 ${isVisible ? 'slide-in-left' : ''}`}>
                <TypeAnimation
                    sequence={[
                        'Transforming Tomorrow with Innovative Solutions in ML, AI, Web & App Development, AR, and VR',
                        1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: '1.2em', display: 'inline-block' }}
                    repeat={Infinity}
                />
                <div className="flex justify-center mt-8 space-x-4 text-sm md:text-base lg:text-lg lg:mt-[4rem]">
                    <button className="bg-[#2980B9] hover:bg-[#1F618D] text-white py-2 px-4 md:py-1 md:px-2 lg:py-2 lg:px-4 rounded shadow-white glow-on-hover" onClick={() => navigate('/products/xai-bot')}>
                        <div className='flex justify-center items-center space-x-1'>
                            <span>Watch Demo</span>
                            <FaYoutube size={20} />
                        </div>
                    </button>
                    <button className="bg-[#2980B9] hover:bg-[#1F618D] text-white py-2 px-4 md:py-1 md:px-2 lg:py-2 lg:px-4 rounded glow-on-hover" onClick={() => navigate('/contact')}>
                        <div className='flex justify-center items-center space-x-1'>
                            <span>Get in Touch</span>
                            <GiClick size={20} />
                        </div>
                    </button>
                </div>
            </div>
            <div className={`${isVisible ? 'slide-in-right' : ''} hidden md:block md:w-[50%]`}>
                <img src={HomeIcon} alt="icon" />
            </div>
        </div>
    )
}

export default Home;