import React from 'react'
import Home from './homePage/Home'
import Services from './homePage/Services'
import Capability from './homePage/Capability'
import Testimonials from './homePage/Testimonials'
import Blog from './homePage/Blogs'
import TestimonialsData from './homePage/TestimonialsData'
import GetInTouch from './homePage/GetInTouch'
import Customers from './homePage/Customers'

function index() {
    return (
        <>
            <Home />
            <Customers />
            <Services />
            <Capability />
            {/* <OurTeam /> */}
            <Blog />
            <Testimonials reviews={TestimonialsData} />
            <GetInTouch />
        </>
    )
}

export default index