import React, { useState, useEffect, useRef } from 'react';
import AboutIcon from '../../assests/services.png';
import analytics from '../../assests/analytics.png';
import automation from '../../assests/automation.png';
import dataProcessing from '../../assests/data-processing.png';
import cyberEye from '../../assests/cyber-eye.png';
import webdev from '../../assests/app-dev.png';
import virtualreality from '../../assests/virtual-reality.png';

function Services() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsVisible(false);
    }, [componentRef]);

    return (
        <div>
            <div className='flex flex-col items-center mt-[1rem] mb-[1rem]'>
                <div className='border border-homeBg rounded-2xl px-2 py-1 mb-3 hover:bg-homeBg'>
                    <p className='text-homeBg hover:text-white'>Our Services</p>
                </div>
                <p className='section-heading px-5'>We Transform Your Business with Our Cutting-Edge Tools for Smarter Solutions.</p>
            </div>
            <div className={`flex justify-center items-center space-x-8 m-[3rem] ${isSmallScreen ? 'flex-col' : ''}`}>
                {!isSmallScreen && (
                    <div ref={componentRef} className={`flex flex-col flex-1 ${isVisible ? 'slide-in-left' : ''}`}>
                        <ServiceCard image={analytics} title="Advanced Data Analytics" description="Harness the power of data to gain actionable insights, make informed decisions, and drive business growth with our advanced analytics solutions." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={automation} title="Intelligent Automation" description="Streamline processes, increase efficiency, and reduce operational costs by implementing intelligent automation solutions that leverage AI & ML technologies." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={webdev} title="Web & App Development" description="Refine your digital presence with custom web and app dev. We specialize in dynamic websites and mobile apps, tailoring solutions to engage your audience." isSmallScreen={isSmallScreen} />
                    </div>
                )}
                {!isSmallScreen && (
                    <div className={`flex justify-center flex-1 ${isSmallScreen ? 'mb-5' : ''}`}>
                        <img src={AboutIcon} alt='About' height="250" width="250" />
                    </div>
                )}
                {!isSmallScreen && (
                    <div ref={componentRef} className={`flex flex-col flex-1 ${isVisible ? 'slide-in-right' : ''}`}>
                        <ServiceCard image={dataProcessing} title="Natural Language Processing" description="Enhance user experiences, automate text analysis, and extract valuable insights from unstructured data using our natural language processing capabilities." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={cyberEye} title="Computer Vision Technology" description="Revolutionize industries, improve safety, and optimize workflows with our computer vision technology, enabling machines to interpret and understand visual information like never before." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={virtualreality} title="Virtual Reality Experiences" description="Immerse your audience in captivating virtual worlds with our cutting-edge VR solutions. From interactive simulations to immersive storytelling, we push the boundaries of imagination." isSmallScreen={isSmallScreen} />
                    </div>
                )}
                {isSmallScreen && (
                    <div ref={componentRef} className={`flex flex-col ${isVisible ? 'slide-in-bottom' : ''}`}>
                        <ServiceCard image={analytics} title="Advanced Data Analytics" description="Harness the power of data to gain actionable insights, make informed decisions, and drive business growth with our advanced analytics solutions." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={automation} title="Intelligent Automation" description="Streamline processes, increase efficiency, and reduce operational costs by implementing intelligent automation solutions that leverage AI & ML technologies." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={webdev} title="Web & App Development" description="Refine your digital presence with custom web and app dev. We specialize in dynamic websites and mobile apps, tailoring solutions to engage your audience." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={dataProcessing} title="Natural Language Processing" description="Enhance user experiences, automate text analysis, and extract valuable insights from unstructured data using our natural language processing capabilities." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={cyberEye} title="Computer Vision Technology" description="Revolutionize industries, improve safety, and optimize workflows with our computer vision technology, enabling machines to interpret and understand visual information like never before." isSmallScreen={isSmallScreen} />
                        <ServiceCard image={virtualreality} title="Virtual Reality Experiences" description="Immerse your audience in captivating virtual worlds with our cutting-edge VR solutions. From interactive simulations to immersive storytelling, we push the boundaries of imagination." isSmallScreen={isSmallScreen} />
                    </div>
                )}
            </div>
        </div>
    );
}


const ServiceCard = ({ image, title, description, isSmallScreen }) => {
    // Small screen version of ServiceCard
    const SmallScreenServiceCard = (
        <div className="flex flex-col bg-bgLight p-3 rounded-lg shadow-md my-5">
            <div className="flex flex-col md:flex-row md:space-x-5">
                <div className="flex justify-center items-center py-3">
                    <img src={image} alt="service" height="110" width="110" />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold text-lg mb-1 whitespace-nowrap">{title}</p>
                    <p className="text-gray-700 text-[0.9rem] text-center">{description}</p>
                </div>
            </div>
        </div>
    );

    // Large screen version of ServiceCard
    const LargeScreenServiceCard = (
        <div className="flex flex-col bg-bgLight p-3 rounded-lg shadow-md my-5">
            <div className='flex space-x-5'>
                <div>
                    <img src={image} alt='service' height="180" width="180" />
                </div>
                <div>
                    <p className='font-semibold text-lg mb-1'>{title}</p>
                    <p className='text-gray-700 text-[0.9rem]'>{description}</p>
                </div>
            </div>
        </div>
    );

    return isSmallScreen ? SmallScreenServiceCard : LargeScreenServiceCard;
};


export default Services;