import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineArrowDropDown, MdOutlineMenu, MdClose } from "react-icons/md";

function Navbar() {
    const [scrolling, setScrolling] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false);
    const [showNestedDropdown, setShowNestedDropdown] = useState(false);
    const [nestedDropdownContent, setNestedDropdownContent] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const dropdownRef = useRef(null);
    const nestedDropdownRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrolling(window.scrollY > 90);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDropdownEnter = () => {
        setShowDropdown(true);
    };

    const handleDropdownLeave = () => {
        setShowDropdown(false);
    };

    const handleNestedDropdownEnter = () => {
        setShowNestedDropdown(true);
    };

    const handleNestedDropdownLeave = () => {
        setShowNestedDropdown(false);
    };

    const handleDropdownHover = (content) => {
        setNestedDropdownContent(content);
        setShowDropdown(true);
    };

    const toggleSmallScreenMenu = () => {
        setShowSmallScreenMenu(!showSmallScreenMenu);
    };

    useEffect(() => {
        return () => {
            setShowDropdown(false);
            setShowSmallScreenMenu(false);
            setShowNestedDropdown(false);
            setNestedDropdownContent([]);
        };
    }, [refresh]);

    const handleNavigation = (path) => {
        setRefresh(prevState => !prevState);
        navigate(path);
    }

    return (
        <div className={`sticky top-0 z-10 flex justify-between items-center p-3 bg-homeBg transition-all duration-300 ${scrolling ? 'shadow-lg' : ''}`}>
            <div className='text-white font-sans font-bold logo ml-2' onClick={() => handleNavigation('/')}>
                <p>Tensor</p>
            </div>
            <div className='flex space-x-4 text-navText font-[500] relative'>
                <div className="lg:hidden mr-2">
                    {showSmallScreenMenu ? (
                        <MdClose onClick={toggleSmallScreenMenu} className="text-white text-3xl cursor-pointer" />
                    ) : (
                        <MdOutlineMenu onClick={toggleSmallScreenMenu} className="text-white text-3xl cursor-pointer" />
                    )}
                </div>
                <div className="hidden lg:flex lg:space-x-4">
                    <p className={`hover:text-white ${location.pathname === '/' ? 'text-white' : ''}`} onClick={() => handleNavigation('/')}>Home</p>
                    <p className={`hover:text-white ${location.pathname === '/about' ? 'text-white' : ''}`} onClick={() => handleNavigation('/about')}>About us</p>
                    <div className="relative" ref={dropdownRef} onMouseEnter={handleDropdownEnter} onMouseLeave={handleDropdownLeave}>
                        <button className={`hover:text-white ${location.pathname.startsWith('/products') ? 'text-white' : ''}`}>
                            <div className='flex justify-center items-center'>
                                <p>Products</p>
                                <MdOutlineArrowDropDown />
                            </div>
                        </button>
                        {showDropdown && (
                            <div className="absolute top-full bg-white shadow-lg w-32 transition-all duration-300" onMouseEnter={handleNestedDropdownEnter} onMouseLeave={handleNestedDropdownLeave}>
                                <div className='bg-gray-100 flex flex-col justify-center'>
                                    <button className="block px-4 py-2 text-sm text-gray-800 hover:bg-bgLight rounded" onMouseEnter={() => handleDropdownHover(["Bhashantar"])}>
                                        Tensor AI
                                    </button>
                                    <button className="block px-4 py-2 text-sm text-gray-800 hover:bg-bgLight rounded" onMouseEnter={() => handleDropdownHover(["Xai Bot"])}>
                                        Tensor LLM
                                    </button>
                                    <button className="block px-4 py-2 text-sm text-gray-800 hover:bg-bgLight rounded" onMouseEnter={() => handleDropdownHover(["Smurfur"])}>
                                        Tensor Play
                                    </button>
                                </div>
                                {showNestedDropdown && (
                                    <div className="absolute top-0 left-full mt-0 bg-white w-28 h-full" ref={nestedDropdownRef}>
                                        {nestedDropdownContent.map((item, index) => (
                                            <p key={index} onClick={() => handleNavigation(`/products/${item.toLowerCase().replace(' ', '-')}`)} className="block px-4 py-2 text-sm text-gray-800 hover:bg-bgLight rounded">{item}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <p className={`hover:text-white ${location.pathname === '/blogs' ? 'text-white' : ''}`} onClick={() => handleNavigation('/blogs')}>Blogs</p>
                    <p className={`hover:text-white ${location.pathname === '/contact' ? 'text-white' : ''}`} onClick={() => handleNavigation('/contact')}>Contact Us</p>
                </div>
            </div>
            {showSmallScreenMenu && (
                <div className="lg:hidden absolute top-full left-0 right-0 bg-homeBg shadow-md text-center">
                    <p className={`block py-2 px-4 text-white ${location.pathname === '/' ? 'font-bold' : ''}`} onClick={() => handleNavigation('/')}>Home</p>
                    <p className={`block py-2 px-4 text-white ${location.pathname === '/about' ? 'font-bold' : ''}`} onClick={() => handleNavigation('/about')}>About us</p>
                    <div className="relative">
                        <button className={`block py-2 px-4 text-white focus:outline-none w-full ${location.pathname.startsWith('/products') ? 'font-bold' : ''}`} onClick={() => setShowNestedDropdown(!showNestedDropdown)}>
                            Products
                            <MdOutlineArrowDropDown className="inline-block ml-1" />
                        </button>
                        {showNestedDropdown && (
                            <div className="bg-homeBg w-full px-4 py-2 transition-all duration-300">
                                <p className="block py-2 text-sm text-white rounded" onClick={() => handleNavigation('/products/bhashantar')}>Tensor AI</p>
                                <p className="block py-2 text-sm text-white rounded" onClick={() => handleNavigation('/products/xai-bot')}>Tensor LLM</p>
                                <p className="block py-2 text-sm text-white rounded" onClick={() => handleNavigation('/products/smurfur')}>Tensor Play</p>
                            </div>
                        )}
                    </div>
                    <p className={`block py-2 px-4 text-white ${location.pathname === '/blogs' ? 'font-bold' : ''}`} onClick={() => handleNavigation('/blogs')}>Blogs</p>
                    <p className={`block py-2 px-4 text-white ${location.pathname === '/contact' ? 'font-bold' : ''}`} onClick={() => handleNavigation('/contact')}>Contact Us</p>
                </div>
            )}
        </div>
    );
}

export default Navbar;