import React, { useState, useEffect } from 'react';
import ncert from '../../assests/customers/ncert.png';
import vigyanprasar from '../../assests/customers/vigyan-prasar.jpeg';
import investindia from '../../assests/customers/invest-india.jpg';
import startupindia from '../../assests/customers/startup-india.png';
import appyHub from '../../assests/customers/appyhub.svg';
import surgiSol from '../../assests/customers/surgisol.jpg';

function Customers() {
    const isSmallScreen = window.innerWidth <= 768;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [ncert, vigyanprasar, investindia, startupindia, appyHub, surgiSol];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images?.length]);

    if (isSmallScreen) {
        return (
            <div className='overflow-hidden mt-8'>
                <div className='flex justify-center items-center space-x-8 p-3 my-[3rem] mt-[2rem]'>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`imgs-${index}`}
                            height={170}
                            width={170}
                            style={{ display: index === currentImageIndex ? 'block' : 'none' }}
                        />
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className='overflow-hidden mt-12'>
                <div className='flex justify-center items-center space-x-8 p-3 my-[3rem] mt-[2rem]'>
                    <img src={ncert} alt='ncert' height={90} width={90} />
                    <img src={vigyanprasar} alt='vigyan-prasar' height={170} width={170} />
                    <img src={investindia} alt='invest-india' height={170} width={170} />
                    <img src={startupindia} alt='startup-india' height={170} width={170} />
                    <img src={appyHub} alt='appyHub' height={170} width={170} />
                    <img src={surgiSol} alt='surgiSol' height={170} width={170} />
                </div>
            </div>
        );
    }
}

export default Customers;