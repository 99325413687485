const TestimonialsData = [
    {
        id: 1,
        name: "Sanskrti Dubey",
        image: "https://lh3.googleusercontent.com/a/ACg8ocJMO3859jpGwWFxcdm6hmZlOimFOkZNUJM8_ApcDiHUo3U7mQ=w60-h60-p-rp-mo-br100",
        text: "One of the best IT and software company in bhopal. They make excellent and responsive websites and Android application. I took their service for my restaurant management and CRM software. Techies are really humble and they get work on time. Thanks Tensor Solutions. Highly recommended.",
    },
    {
        id: 2,
        name: "Vidhi Patidar",
        image: "https://lh3.googleusercontent.com/a/ACg8ocLIRVDZcOGEyywmXTXfJsY8F3sWb23f0hNltuh35TOKDTTSbQ=w60-h60-p-rp-mo-br100",
        text: "Great environment and experienced techies. Thanks for completing Hospital CRM software on time.",
    },
    {
        id: 3,
        name: "Himanshu Swamy",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjU9VJgXnJyQMPd_EenArMHPw-xW9H08-GIr9Vvgy05YcWQaNy_e=w60-h60-p-rp-mo-br100",
        text: "They developed Restaurant Management Software for my restaurant business. Highly Recommended.",
    },
    {
        id: 4,
        name: "Sakshi Tripathi",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjVM8T3vyaB1ybAEIwPc8Q7LjOlIMgTq06j-O_9fUnE6XTjPyx_drQ=w60-h60-p-rp-mo-br100",
        text: "One of the most recommended and promising software services in Bhopal.",
    },
    {
        id: 5,
        name: "Rahul Meena",
        image: "https://lh3.googleusercontent.com/a/ACg8ocKC9FLXcAV0Ttc0dqQf6TzvW5YZhkMV4D1V49_oAYKh8lBnFA=w60-h60-p-rp-mo-br100",
        text: "Best and quality software services in Bhopal.",
    },
    {
        id: 6,
        name: "Hrithik Dubey",
        image: "https://lh3.googleusercontent.com/a/ACg8ocKtMKNv7HsY1ZrmYPimwCDyvGlu2jQ_1D3bWkFPOEV9_pU8uw=w60-h60-p-rp-mo-br100",
        text: "Great software services in bhopal.",
    },
];

export default TestimonialsData;
